import { PageId, WidgetsId } from '../../constants';
import { BasePageMigrator } from './base-page-migrator';
import {
  getBookingsDefId,
  getPageData,
  getTPAMainSectionPageRef,
  getTPAMainSectionStructure,
  isBookingsCheckoutInstalled, isBookingsListInstalled,
  isEcomCheckoutInstalled,
  updatePageData,
} from '../editor-sdk-actions';
import { MigrationHandler } from '../../migration/migration-handler';
import {
  getSchedulerStyles,
  setBookingsListStyles,
} from '../list-migration/schedule-styles-migration';
import { addBookCheckoutPage } from '../pages-actions';
import { addBookingsPagesAsPanel } from '../pages-panel-actions';

export class ServiceListMigrator extends BasePageMigrator {
  constructor(protected editorSDK, protected appToken, protected instance) {
    super(editorSDK, appToken, instance);
  }

  public async shouldMigrate(): Promise<boolean> {
    const bookingsCheckoutInstalled = await isBookingsCheckoutInstalled(this.editorSDK);
    const bookingsListInstalled = await isBookingsListInstalled(this.editorSDK);

    return (!bookingsListInstalled || !bookingsCheckoutInstalled);
  }

  public async execute() {
    const bookingsListInstalled = await isBookingsListInstalled(this.editorSDK);
    const bookingsCheckoutInstalled = await isBookingsCheckoutInstalled(this.editorSDK);
    const bookingsDefId = await getBookingsDefId(this.editorSDK);
    const colorsMap = await this.editorSDK.document.theme.colors.getAll();
    const migratory = new MigrationHandler(
      this.editorSDK,
      this.appToken,
      bookingsDefId,
    );
    const schedulerSectionStructure = await getTPAMainSectionStructure(
      this.editorSDK,
      this.appToken,
      bookingsDefId,
    );
    if (!schedulerSectionStructure) {
      throw new Error('schedulerSectionStructure not found in getTPAMainSectionStructure - errorCode: SCHEDULER_NOT_FOUND');
    }
    const schedulerSectionRef = await getTPAMainSectionPageRef(
      this.editorSDK,
      this.appToken,
      bookingsDefId,
    );
    const schedulerData = await getPageData(
      this.editorSDK,
      this.appToken,
      schedulerSectionRef,
    );
    const schedulerStyle = await getSchedulerStyles(
      schedulerSectionStructure.style,
      colorsMap,
    );
    console.log('New Properties:');
    console.table(schedulerStyle);

    // add bookings service list
    if (!bookingsListInstalled) {
      await migratory.migrateSection(
        schedulerSectionStructure,
        WidgetsId.BOOKINGS_LIST_PAGE,
        PageId.BOOKINGS_LIST,
      );

      const compId = schedulerSectionStructure.id;

      await setBookingsListStyles(
        this.editorSDK,
        this.appToken,
        schedulerStyle,
        compId,
      );
    }

    // add bookings checkout page
    if (!bookingsCheckoutInstalled) {
      const bookCheckoutPage = await addBookCheckoutPage(
        this.editorSDK,
        bookingsDefId,
        this.appToken,
        schedulerSectionStructure.style,
      );
      const bookCheckoutPageData = await getPageData(
        this.editorSDK,
        this.appToken,
        bookCheckoutPage.pageRef,
      );
      schedulerData.pageBackgrounds.desktop.ref.id =
        bookCheckoutPageData.pageBackgrounds.desktop.ref.id;
      schedulerData.pageBackgrounds.mobile.ref.id =
        bookCheckoutPageData.pageBackgrounds.mobile.ref.id;
      await updatePageData(
        this.editorSDK,
        this.appToken,
        bookCheckoutPage.pageRef,
        {
          pageSecurity: { ...schedulerData.pageSecurity },
          pageBackgrounds: { ...schedulerData.pageBackgrounds },
        },
      );
    }

    await addBookingsPagesAsPanel(this.editorSDK, this.appToken);
  }

  protected get widgetId() {
    return '54d912c5-52cb-4657-b8fa-e1a4cda8ed01';
  }

  public get widgetName() {
    return 'ServiceListMigrator';
  }
}
